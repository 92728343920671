import styled, { css } from "styled-components";

export const CheckBoxContainer = styled.div`
  display: flex;
  ${({ reverse }) =>
    reverse &&
    css`
      flex-direction: row-reverse;
      justify-content: flex-end;
    `}
`;

export const StyledCheckBox = styled.input`
  margin-left: ${({ theme, label, reverse }) =>
    label && reverse && (theme?.components?.checkbox?.marginLeft || "10px")};

  margin-right: ${({ theme, label, reverse }) =>
    label && !reverse && (theme?.components?.checkbox?.marginRight || "10px")};
`;

export const CheckBoxLabel = styled.div``;
