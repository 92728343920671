import {
  Button,
  DropDown,
  Heading,
  Icon,
  Input,
  TextArea,
} from "darshanyeah-react-ui";
import React, { useState } from "react";
import ModernDatepicker from "react-modern-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import {
  RESUME_PAGE_ERROR_1,
  RESUME_PAGE_ERROR_2,
  RESUME_PAGE_ERROR_3,
  RESUME_PAGE_SUCCESS_1,
} from "../../utils/reactToastifyToastIds";
import { emailPattern, mobilePattern } from "../../utils/regex";
import { SubmitBtnContainer } from "../Contact/Contact.css";
import {
  AddMoreButton,
  ErrorText,
  FormContainer,
  Grid,
  HintText,
  InputContainer,
  Label,
  Radio,
  RemoveButton,
  ResumeContainer,
  SubDetailsContainer,
  SubTitle,
} from "./Resume.css";
import useObjectHasKey from "../../utils/useObjectHasKey";
import CryptoJS from "crypto-js";

export default function Resume() {
  const [record, setRecord] = useState({
    mobile: "91",
    qualification: {
      0: {},
    },
    experience: {
      0: {},
    },
  });
  const [errors, setErrors] = useState({
    qualification: {
      0: {},
    },
    experience: {
      0: {},
    },
  });
  const [educationCount, setEducationCount] = useState([0]);
  const [experienceCount, setExperienceCount] = useState([0]);

  const handleChange = (key, value) => {
    if (key === "mobile") {
      if (value.length > 2) setRecord((prev) => ({ ...prev, [key]: value }));
    } else setRecord((prev) => ({ ...prev, [key]: value }));
  };

  const handleChangeEducation = (key, value, step) => {
    setRecord((prev) => ({
      ...prev,
      qualification: {
        ...prev?.qualification,
        [step]: {
          ...prev?.qualification[step],
          [key]: value,
        },
      },
    }));
  };

  const handleChangeExperience = (key, value, step) => {
    setRecord((prev) => ({
      ...prev,
      experience: {
        ...prev?.experience,
        [step]: {
          ...prev?.experience[step],
          [key]: value,
        },
      },
    }));
  };

  const handleAddEducation = () => {
    setRecord((prev) => ({
      ...prev,
      qualification: {
        ...prev.qualification,
        [educationCount.length]: {},
      },
    }));
    setEducationCount((prev) => [...prev, prev?.length]);
  };

  const handleRemoveEducation = (id) => {
    const newRecord = record.qualification;
    delete newRecord[id];
    const newCount = educationCount.filter((e) => e !== id);
    setRecord((prev) => ({
      ...prev,
      qualification: newRecord,
    }));
    setEducationCount(newCount);
  };

  const handleAddExperience = () => {
    setRecord((prev) => ({
      ...prev,
      experience: {
        ...prev.experience,
        [experienceCount.length]: {},
      },
    }));
    setExperienceCount((prev) => [...prev, experienceCount.length]);
  };

  const handleRemoveExperience = (id) => {
    const newRecord = record.experience;
    delete newRecord[id];
    const newCount = experienceCount.filter((e) => e !== id);
    setRecord((prev) => ({
      ...prev,
      experience: newRecord,
    }));
    setExperienceCount(newCount);
  };

  const handleChangeGender = (e) => {
    let value = "Male";
    if (e === 1) value = "Female";
    setRecord((prev) => ({ ...prev, gender: value, genderIndex: e }));
  };

  const onChangeDob = (value) => {
    if (value !== "Invalid Date")
      setRecord((prev) => ({ ...prev, dob: value }));
  };

  const handleError = (key, value) => {
    setErrors((prev) => ({ ...prev, [key]: value }));
  };

  const handleErrorEducation = (key, value, step) => {
    setErrors((prev) => ({
      ...prev,
      qualification: {
        ...prev?.qualification,
        [step]: {
          ...prev?.qualification[step],
          [key]: value,
        },
      },
    }));
  };

  const handleErrorExperience = (key, value, step) => {
    setErrors((prev) => ({
      ...prev,
      experience: {
        ...prev?.experience,
        [step]: {
          ...prev?.experience[step],
          [key]: value,
        },
      },
    }));
  };

  const namekey = useObjectHasKey(record, "fullname");
  const dobkey = useObjectHasKey(record, "dob");
  const genderkey = useObjectHasKey(record, "gender");
  const emailkey = useObjectHasKey(record, "email");
  const mobilekey = useObjectHasKey(record, "mobile");
  const addresskey = useObjectHasKey(record, "address");
  const skillskey = useObjectHasKey(record, "skills");
  const salarykey = useObjectHasKey(record, "expectedSalary");
  const highereducationkey = useObjectHasKey(record, "higherEducation");

  const validate = () => {
    let valid = false;
    let validState = true;

    function showInvalidError(key) {
      if (key) handleError(key, true);
      valid = false;
      toast.error("Please fill mandatory fields !!", {
        toastId: RESUME_PAGE_ERROR_1,
      });
    }

    // check full name
    if (namekey && record?.fullname?.length >= 5) {
      handleError("fullname", false);
      valid = true;
    } else {
      showInvalidError("fullname");
      return false;
    }

    // check date of birth
    if (dobkey && record?.dob?.length >= 5) {
      handleError("dob", false);
      valid = true;
    } else {
      showInvalidError("dob");
      return false;
    }

    // check gender
    if (genderkey) {
      handleError("gender", false);
      valid = true;
    } else {
      showInvalidError("gender");
      return false;
    }

    // check email
    if (emailkey && emailPattern.test(record?.email)) {
      handleError("email", false);
      valid = true;
    } else {
      showInvalidError("email");
      return false;
    }

    // check mobile
    if (mobilekey && mobilePattern.test(record?.mobile)) {
      handleError("mobile", false);
      valid = true;
    } else {
      showInvalidError("mobile");
      return false;
    }

    // check address
    if (addresskey && record?.address?.length >= 10) {
      handleError("address", false);
      valid = true;
    } else {
      showInvalidError("address");
      return false;
    }

    function qualificationTest(step, key) {
      if (
        record?.qualification[step][key] &&
        record?.qualification[step][key]?.length >= 2
      ) {
        handleErrorEducation(key, false, step);
        valid = true;
        validState = true;
      } else {
        handleErrorEducation(key, true, step);
        showInvalidError();
        valid = false;
        validState = false;
        return false;
      }
    }

    // check degree
    educationCount.forEach((step) => qualificationTest(step, "degree"));
    if (!validState) return false;

    // check college name
    educationCount.forEach((step) => qualificationTest(step, "collegeName"));
    if (!validState) return false;

    // check start date
    educationCount.forEach((step) => qualificationTest(step, "startDate"));
    if (!validState) return false;

    // check end date
    educationCount.forEach((step) => qualificationTest(step, "endDate"));
    if (!validState) return false;

    // higher education
    if (highereducationkey && record?.higherEducation > -1) {
      handleError("higherEducation", false);
      valid = true;
    } else {
      showInvalidError("higherEducation");
      return false;
    }

    function experienceTest(step, key) {
      if (
        record?.experience[step][key] &&
        record?.experience[step][key]?.length >= 2
      ) {
        handleErrorExperience(key, false, step);
        valid = true;
        validState = true;
      } else {
        handleErrorExperience(key, true, step);
        valid = false;
        validState = false;
        showInvalidError();
      }
    }

    // check designation
    experienceCount.forEach((step) => experienceTest(step, "designation"));
    if (!validState) return false;

    // check hospital
    experienceCount.forEach((step) => experienceTest(step, "hospital"));
    if (!validState) return false;

    // check address
    experienceCount.forEach((step) => experienceTest(step, "address"));
    if (!validState) return false;

    // check duration
    experienceCount.forEach((step) => experienceTest(step, "duration"));
    if (!validState) return false;

    // check skills
    if (skillskey && record?.skills?.length >= 5) {
      handleError("skills", false);
      valid = true;
    } else {
      showInvalidError("skills");
      return false;
    }

    // check expectedSalary
    if (salarykey && record?.expectedSalary?.length >= 2) {
      handleError("expectedSalary", false);
      valid = true;
    } else {
      showInvalidError("expectedSalary");
      return false;
    }

    return valid;
  };

  const cryptoKey = process.env.REACT_APP_CRYPTO_KEY;

  const handleSubmit = () => {
    if (validate()) {
      const data = {
        ...record,
        education: CryptoJS.DES.encrypt(
          JSON.stringify(record.qualification),
          cryptoKey
        ).toString(),
        experience: CryptoJS.DES.encrypt(
          JSON.stringify(record.experience),
          cryptoKey
        ).toString(),
        salary: record?.expectedSalary,
        message: record?.message || "",
      };
      delete data.qualification;
      delete data.expectedSalary;
      delete data.genderIndex;
      fetch("/api/resume/", {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === 1) {
            toast.success("Form Submitted Successfully", {
              toastId: RESUME_PAGE_SUCCESS_1,
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            toast.error("Opps, something has gone wrong", {
              toastId: RESUME_PAGE_ERROR_2,
            });
            toast.error("Form submission failed !!!", {
              toastId: RESUME_PAGE_ERROR_3,
            });
          }
        })
        .catch((e) => {
          toast.error("Opps, something has gone wrong", {
            toastId: RESUME_PAGE_ERROR_2,
          });
          toast.error("Form submission failed !!!", {
            toastId: RESUME_PAGE_ERROR_3,
          });
        });
    }
  };

  return (
    <ResumeContainer>
      <FormContainer>
        <Heading className="title" variant="h5">
          Create Resume
        </Heading>
        <Heading variant="p">
          Below details are used to introduce you to the hospitals so please
          fill it properly.
        </Heading>
        <Heading className="note" variant="p">
          Fields marked with * are mandatory
        </Heading>
        <SubTitle variant="h6">
          <Icon iconName="bs/BsPersonLinesFill" />
          &nbsp;&nbsp;Personal Details
        </SubTitle>
        <InputContainer>
          <Input
            labelText="Full Name"
            inputType="text"
            errorText="Please introduce a valid name !"
            onChange={(event) => handleChange("fullname", event.target.value)}
            error={errors?.fullname}
            required
          />
        </InputContainer>
        <InputContainer zIndex={1}>
          <Label hasHint>
            Date of Birth <Icon iconName="cg/CgAsterisk" />
          </Label>
          <HintText hasLabel>DD-MM-YYYY</HintText>
          <ModernDatepicker
            className="date-picker"
            date={record.dob}
            format={"DD-MM-YYYY"}
            maxDate={moment().format("DD-MM-YYYY")}
            showBorder
            allowEdit
            onChange={onChangeDob}
            placeholder={"Select Date of Birth"}
          />
          {errors?.dob && (
            <ErrorText>Please introduce a valid date of birth !</ErrorText>
          )}
        </InputContainer>
        <InputContainer>
          <Label>
            Gender <Icon iconName="cg/CgAsterisk" />
          </Label>
          <DropDown
            title={record?.gender || "Gender"}
            items={[
              <Heading variant="p">Male</Heading>,
              <Heading variant="p">Female</Heading>,
            ]}
            selectedIndex={record?.genderIndex}
            setSelectedIndex={handleChangeGender}
          />
          {errors?.gender && (
            <ErrorText>Please introduce a valid gender !</ErrorText>
          )}
        </InputContainer>
        <InputContainer>
          <Input
            labelText="Email"
            inputType="email"
            errorText="The entered E-MAIL is invalid !"
            onChange={(event) => handleChange("email", event.target.value)}
            error={errors?.email}
            required
          />
        </InputContainer>
        {/* +91 compulsory automatic */}
        <InputContainer>
          <Input
            labelText="Mobile Number"
            inputType="number"
            hint="Please add the country code"
            errorText="Please enter valid Mobile Number !"
            defValue={record?.mobile}
            min={91}
            onChange={(event) => {
              if (event.target.value.length > 3)
                handleChange("mobile", event.target.value);
              else handleChange("mobile", "91");
            }}
            error={errors?.mobile}
            required
          />
        </InputContainer>
        <InputContainer>
          <Input
            labelText="Current Postal Address"
            inputType="text"
            errorText="Please introduce a valid address !"
            onChange={(event) => handleChange("address", event.target.value)}
            error={errors?.address}
            required
          />
        </InputContainer>
        <SubDetailsContainer>
          <Heading variant="h6">
            <Icon iconName="bs/BsBookHalf" />
            &nbsp;&nbsp;Education Details
          </Heading>
          {educationCount?.map((e) => (
            <Grid key={e}>
              <InputContainer>
                <Input
                  labelText="Degree"
                  inputType="text"
                  hint="e.g. M.B.B.S."
                  errorText="Please introduce a valid degree !"
                  onChange={(event) =>
                    handleChangeEducation("degree", event.target.value, e)
                  }
                  error={errors.qualification[e]?.degree}
                  required
                />
              </InputContainer>
              <InputContainer>
                <Input
                  labelText="College Name"
                  inputType="text"
                  hint="Please add college name with address"
                  errorText="Please introduce a valid college name !"
                  onChange={(event) =>
                    handleChangeEducation("collegeName", event.target.value, e)
                  }
                  error={errors.qualification[e]?.collegeName}
                  required
                />
              </InputContainer>
              <InputContainer zIndex={2}>
                <Label hasHint>
                  Start Date <Icon iconName="cg/CgAsterisk" />
                </Label>
                <HintText hasLabel>DD-MM-YYYY</HintText>
                <ModernDatepicker
                  className="date-picker"
                  date={record?.qualification[e]?.startDate}
                  format={"DD-MM-YYYY"}
                  maxDate={moment().format("DD-MM-YYYY")}
                  showBorder
                  allowEdit
                  onChange={(value) => {
                    if (value !== "Invalid Date")
                      handleChangeEducation("startDate", value, e);
                  }}
                />
                {errors?.qualification[e]?.startDate && (
                  <ErrorText>Please introduce a valid start date !</ErrorText>
                )}
              </InputContainer>
              <InputContainer zIndex={1}>
                <Label hasHint>
                  End Date <Icon iconName="cg/CgAsterisk" />
                </Label>
                <HintText hasLabel>DD-MM-YYYY</HintText>
                <ModernDatepicker
                  className="date-picker"
                  date={record?.qualification[e]?.endDate}
                  format={"DD-MM-YYYY"}
                  maxDate={moment().format("DD-MM-YYYY")}
                  showBorder
                  allowEdit
                  onChange={(value) => {
                    if (value !== "Invalid Date")
                      handleChangeEducation("endDate", value, e);
                  }}
                />
                {errors?.qualification[e]?.endDate && (
                  <ErrorText>Please introduce a valid end date !</ErrorText>
                )}
              </InputContainer>
              <InputContainer className="higherEducationContainer">
                <Label hasHint={true}>Is this your highest education..?</Label>
                <Radio
                  id={`hiEdYes-${e}`}
                  type="radio"
                  checked={record?.higherEducation === e}
                  onChange={(event) => handleChange("higherEducation", e)}
                  required
                />
                <Label htmlFor={`hiEdYes-${e}`} hasHint={true}>
                  Yes
                </Label>
                {errors?.higherEducation && (
                  <ErrorText>Please introduce valid Higher Education</ErrorText>
                )}
              </InputContainer>
              {educationCount.length > 1 && e > 0 && (
                <RemoveButton>
                  <Button
                    label="Remove"
                    variant="transparent-bordered"
                    icon="md/MdRemove"
                    onClick={() => handleRemoveEducation(e)}
                    iconBefore
                  />
                </RemoveButton>
              )}
            </Grid>
          ))}
          <AddMoreButton>
            <Button
              label="Add Another Education"
              variant="transparent-bordered"
              icon="md/MdAdd"
              onClick={handleAddEducation}
            />
          </AddMoreButton>
        </SubDetailsContainer>
        <SubDetailsContainer>
          <Heading variant="h6">
            <Icon iconName="fa/FaGraduationCap" />
            &nbsp;&nbsp;Experience Details
          </Heading>
          {experienceCount?.map((e) => (
            <Grid key={e}>
              <InputContainer>
                <Input
                  labelText="Designation"
                  inputType="text"
                  hint="Please tell us your designation"
                  errorText="Please introduce a valid designation !"
                  onChange={(event) =>
                    handleChangeExperience("designation", event.target.value, e)
                  }
                  error={errors.experience[e]?.designation}
                  required
                />
              </InputContainer>
              <InputContainer>
                <Input
                  labelText="Hospital / Organization Name"
                  inputType="text"
                  hint="Please tell us hospital name in details"
                  errorText="Please introduce a valid hospital name !"
                  onChange={(event) =>
                    handleChangeExperience("hospital", event.target.value, e)
                  }
                  error={errors.experience[e]?.hospital}
                  required
                />
              </InputContainer>
              <InputContainer>
                <Input
                  labelText="Hospital / Organization Address"
                  inputType="text"
                  hint="Please tell us hospital address in details"
                  errorText="Please introduce a valid hospital address !"
                  onChange={(event) =>
                    handleChangeExperience("address", event.target.value, e)
                  }
                  error={errors.experience[e]?.address}
                  required
                />
              </InputContainer>
              <InputContainer>
                <Input
                  labelText="Duration"
                  inputType="text"
                  hint="Please tell us duration of your work"
                  errorText="Please introduce a valid duration !"
                  onChange={(event) =>
                    handleChangeExperience("duration", event.target.value, e)
                  }
                  placeholder="e.g. 1 Year, 6 Month / 2019-Present"
                  error={errors.experience[e]?.duration}
                  required
                />
              </InputContainer>
              {experienceCount.length > 1 && e > 0 && (
                <RemoveButton>
                  <Button
                    label="Remove"
                    variant="transparent-bordered"
                    icon="md/MdRemove"
                    onClick={() => handleRemoveExperience(e)}
                    iconBefore
                  />
                </RemoveButton>
              )}
            </Grid>
          ))}
          <AddMoreButton>
            <Button
              label="Add Another Experience"
              variant="transparent-bordered"
              icon="md/MdAdd"
              onClick={handleAddExperience}
              iconBefore
            />
          </AddMoreButton>
        </SubDetailsContainer>

        <InputContainer>
          <Input
            labelText="Practical Skills"
            inputType="text"
            hint="Please add Key(Practical) skills related to your profile"
            errorText="Please introduce a valid skills !"
            onChange={(event) => handleChange("skills", event.target.value)}
            error={errors?.skills}
            required
          />
        </InputContainer>

        <InputContainer>
          <Input
            labelText="Expected Salary"
            inputType="text"
            onChange={(event) =>
              handleChange("expectedSalary", event.target.value)
            }
            errorText="Please introduce a valid expected salary !"
            required
            error={errors?.expectedSalary}
            leftIcon={{
              name: "bi/BiRupee",
            }}
          />
        </InputContainer>
        <InputContainer>
          <TextArea
            labelText="Message"
            onChange={(event) => handleChange("message", event.target.value)}
            width="auto"
          />
        </InputContainer>
        <SubmitBtnContainer>
          <Button
            label="Submit"
            variant="primary-bordered"
            onClick={handleSubmit}
          />
        </SubmitBtnContainer>
      </FormContainer>
    </ResumeContainer>
  );
}
