import React from "react";
import classnames from "classnames";
import DrawerToggleButton from "./SideDrawer/DrawerToggleButton";
import "./Toolbar.css";
import { Link } from "react-router-dom";

class Toolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
    };
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    });
  };

  render() {
    return (
      <header
        className={classnames("toolbar", {
          "toolbar--hidden": !this.state.visible,
        })}
      >
        <nav className="toolbar_navigation">
          <div className="toolbar_logo">
            <Link to="/">HP Doctor Placement</Link>
            <p>PLACEMENT CELL GUJARAT PRIVATE HOSPITALS</p>
          </div>
          <div className="spacer" />
          <div className="toolbar_toggle-button">
            <DrawerToggleButton click={this.props.drawerClickHandler} />
          </div>
          <menu className="toolbar_navigation-items">
            <ul>
              <li>
                <Link
                  className={classnames("links", {
                    active: window.location.pathname === "/contact",
                  })}
                  to="/contact"
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  className={classnames("links", {
                    active: window.location.pathname === "/post-resume",
                  })}
                  to="/post-resume"
                >
                  Submit Resume
                </Link>
              </li>
              <li>
                <Link
                  className={classnames("links", {
                    active:
                      window.location.pathname === "/hospital-requirement",
                  })}
                  to="/hospital-requirement"
                >
                  Hospital Requirement
                </Link>
              </li>
            </ul>
          </menu>
        </nav>
      </header>
    );
  }
}

export default Toolbar;
