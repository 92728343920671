import React, { useState, useMemo } from "react";
import { Button, Heading, Icon, Input, TextArea } from "darshanyeah-react-ui";
import { toast } from "react-toastify";
import { CheckBox } from "../component/CheckBox";
import { SubmitBtnContainer } from "../Contact/Contact.css";
import {
  FormContainer,
  InputContainer,
  RequirementContainer,
} from "./Requirement.css";
import {
  REQUIREMENT_PAGE_ERROR_1,
  REQUIREMENT_PAGE_ERROR_2,
  REQUIREMENT_PAGE_ERROR_3,
  REQUIREMENT_PAGE_SUCCESS_1,
} from "../../utils/reactToastifyToastIds";
import { emailPattern, mobilePattern } from "../../utils/regex";
import useObjectHasKey from "../../utils/useObjectHasKey";

export default function Requirement() {
  const [record, setRecord] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (key, value) => {
    setRecord((prev) => ({ ...prev, [key]: value }));
    if (key === "fresher") setRecord((prev) => ({ ...prev, expd: !value }));
    if (key === "expd") setRecord((prev) => ({ ...prev, fresher: !value }));
  };

  const handleError = (key, value) => {
    setErrors((prev) => ({ ...prev, [key]: value }));
  };

  const hospitalkey = useObjectHasKey(record, "hospitalname");
  const ownerkey = useObjectHasKey(record, "hospitalownername");
  const addresskey = useObjectHasKey(record, "address");
  const emailkey = useObjectHasKey(record, "email");
  const mobilekey = useObjectHasKey(record, "mobilenumber");
  const doctorkey = useObjectHasKey(record, "doctor");
  const salarykey = useObjectHasKey(record, "salary");
  const fresherkey = useObjectHasKey(record, "fresher");
  const experiencekey = useObjectHasKey(record, "experience");

  useMemo(() => {
    function showInvalidError(key) {
      handleError(key, true);
    }
    // check hospital name
    if (hospitalkey) {
      if (record?.hospitalname?.length >= 5) {
        handleError("hospitalname", false);
      } else showInvalidError("hospitalname");
    }

    // check hospital owner name
    if (ownerkey) {
      if (record?.hospitalownername?.length >= 5) {
        handleError("hospitalownername", false);
      } else showInvalidError("hospitalownername");
    }

    // check address
    if (addresskey) {
      if (record?.address?.length >= 10) {
        handleError("address", false);
      } else showInvalidError("address");
    }

    // check email
    if (emailkey) {
      if (emailPattern.test(record?.email)) {
        handleError("email", false);
      } else showInvalidError("email");
    }

    // check mobile
    if (mobilekey) {
      if (mobilePattern.test(record?.mobilenumber)) {
        handleError("mobilenumber", false);
      } else showInvalidError("mobilenumber");
    }

    // check doctor
    if (doctorkey) {
      if (record?.doctor?.length >= 4) {
        handleError("doctor", false);
      } else showInvalidError("doctor");
    }

    // check salary
    if (salarykey) {
      if (record?.salary >= 100000) {
        handleError("salary", false);
      } else showInvalidError("salary");
    }

    // check experience
    if (fresherkey) {
      if (record?.fresher && record?.expd === false) {
        handleError("fresher", false);
      } else {
        if (experiencekey) {
          if (record?.experience?.length >= 4) {
            handleError("experience", false);
          } else showInvalidError("experience");
        }
      }
    }
  }, [
    addresskey,
    doctorkey,
    emailkey,
    experiencekey,
    fresherkey,
    hospitalkey,
    mobilekey,
    ownerkey,
    record,
    salarykey,
  ]);

  const validate = () => {
    let valid = false;

    function showInvalidError(key) {
      handleError(key, true);
      valid = false;
      toast.error("Please fill mandatory fields !!", {
        toastId: REQUIREMENT_PAGE_ERROR_1,
      });
    }

    // check hospital name
    if (hospitalkey && record?.hospitalname?.length >= 5) {
      handleError("hospitalname", false);
      valid = true;
    } else {
      showInvalidError("hospitalname");
      return false;
    }

    // check hospital owner name
    if (ownerkey && record?.hospitalownername?.length >= 5) {
      handleError("hospitalownername", false);
      valid = true;
    } else {
      showInvalidError("hospitalownername");
      return false;
    }

    // check address
    if (addresskey && record?.address?.length >= 10) {
      handleError("address", false);
      valid = true;
    } else {
      showInvalidError("address");
      return false;
    }

    // check email
    if (emailkey && emailPattern.test(record?.email)) {
      handleError("email", false);
      valid = true;
    } else {
      showInvalidError("email");
      return false;
    }

    // check mobile
    if (mobilekey && mobilePattern.test(record?.mobilenumber)) {
      handleError("mobilenumber", false);
      valid = true;
    } else {
      showInvalidError("mobilenumber");
      return false;
    }

    // check doctor
    if (doctorkey && record?.doctor?.length >= 4) {
      handleError("doctor", false);
      valid = true;
    } else {
      showInvalidError("doctor");
      return false;
    }

    // check salary
    if (salarykey && record?.salary >= 100000) {
      handleError("salary", false);
      valid = true;
    } else {
      showInvalidError("salary");
      return false;
    }

    // check experience
    if (fresherkey) {
      if (record?.fresher && record?.expd === false) {
        handleError("fresher", false);
        valid = true;
      } else {
        if (experiencekey && record?.experience?.length >= 4) {
          handleError("experience", false);
          valid = true;
        } else {
          showInvalidError("experience");
          return false;
        }
      }
    } else {
      showInvalidError("fresher");
      return false;
    }

    return valid;
  };

  const handleSubmit = () => {
    if (validate()) {
      fetch("/api/requirement/", {
        method: "POST",
        body: JSON.stringify({
          hospitalName: record.hospitalname,
          ownerName: record.hospitalownername,
          address: record.address,
          email: record.email,
          mobile: record.mobilenumber,
          requiredDoctor: record.doctor,
          affordableSalary: record.salary,
          fresher: record.fresher,
          experience: record?.experience || null,
          message: record?.message || null,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === 1) {
            toast.success("Form Submitted Successfully", {
              toastId: REQUIREMENT_PAGE_SUCCESS_1,
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            toast.error("Opps, something has gone wrong", {
              toastId: REQUIREMENT_PAGE_ERROR_2,
            });
            toast.error("Form submission failed !!!", {
              toastId: REQUIREMENT_PAGE_ERROR_3,
            });
          }
        })
        .catch((e) => {
          toast.error("Opps, something has gone wrong", {
            toastId: REQUIREMENT_PAGE_ERROR_2,
          });
          toast.error("Form submission failed !!!", {
            toastId: REQUIREMENT_PAGE_ERROR_3,
          });
        });
    }
  };

  return (
    <RequirementContainer>
      <FormContainer>
        <Heading className="title" variant="h5">
          Create Requirement
        </Heading>
        <Heading variant="p">
          Below details are used to introduce hospital so please fill it
          properly, Please create individual for each requirement.
        </Heading>
        <Heading className="note" variant="p">
          Fields marked with * are mandatory
        </Heading>
        <InputContainer>
          <Input
            labelText="Hospital Name"
            inputType="text"
            errorText="Please introduce a valid hospital name !"
            onChange={(event) =>
              handleChange("hospitalname", event.target.value)
            }
            error={errors?.hospitalname}
            required
          />
        </InputContainer>
        <InputContainer>
          <Input
            labelText="Hospital Owner Name"
            inputType="text"
            hint="Please provide full name"
            errorText="Please introduce a valid hospital owner name !"
            onChange={(event) =>
              handleChange("hospitalownername", event.target.value)
            }
            error={errors?.hospitalownername}
            required
          />
        </InputContainer>
        <InputContainer>
          <Input
            labelText="Hospital Address"
            inputType="text"
            hint="Please provide full addresss"
            errorText="Please introduce a valid hospital address !"
            onChange={(event) => handleChange("address", event.target.value)}
            error={errors?.address}
            required
          />
        </InputContainer>
        <InputContainer>
          <Input
            labelText="Email"
            inputType="email"
            hint="Owner's email address"
            errorText="Please introduce a valid email address !"
            onChange={(event) => handleChange("email", event.target.value)}
            error={errors?.email}
            required
          />
        </InputContainer>
        <InputContainer>
          <Input
            labelText="Mobile Number"
            inputType="number"
            hint="Owner's contact number, do not add contry code"
            errorText="Please introduce a valid contact number !"
            onChange={(event) =>
              handleChange("mobilenumber", event.target.value)
            }
            min={0}
            error={errors?.mobilenumber}
            required
          />
        </InputContainer>
        <InputContainer>
          <Input
            labelText="Required Doctor"
            inputType="text"
            hint="Doctor's speciality eg. Neuro Surgeon"
            errorText="Please introduce a valid required doctor !"
            onChange={(event) => handleChange("doctor", event.target.value)}
            error={errors?.doctor}
            required
          />
        </InputContainer>
        <InputContainer>
          <Input
            labelText="Affordable Salary"
            inputType="number"
            min={100000}
            hint="Salary per month"
            errorText="Please introduce a valid affordable salary !"
            onChange={(event) => handleChange("salary", event.target.value)}
            error={errors?.salary}
            required
          />
        </InputContainer>
        <InputContainer>
          <Heading className="label" variant="p">
            Which type of doctor required <Icon iconName="cg/CgAsterisk" />
          </Heading>
          <CheckBox
            checked={record?.fresher}
            label="Fresher"
            onChange={(event) => handleChange("fresher", event.target.checked)}
          />
          <CheckBox
            checked={record?.expd}
            label="Experienced"
            onChange={(event) => handleChange("expd", event.target.checked)}
          />
        </InputContainer>
        {record?.expd && (
          <InputContainer>
            <Input
              labelText="Required Experience"
              inputType="text"
              hint="Minimum how much experience required"
              errorText="Please introduce a valid required experience !"
              onChange={(event) =>
                handleChange("experience", event.target.value)
              }
              error={errors?.experience}
              required
            />
          </InputContainer>
        )}
        <InputContainer>
          <TextArea
            labelText="Message"
            onChange={(event) => handleChange("message", event.target.value)}
            width="auto"
          />
        </InputContainer>
        <InputContainer>
          <Heading variant="p">
            By submitting form you're agreed to our Terms {`&`} Conditions.
          </Heading>
        </InputContainer>
        <SubmitBtnContainer>
          <Button
            label="Submit"
            variant="primary-bordered"
            onClick={handleSubmit}
          />
        </SubmitBtnContainer>
      </FormContainer>
    </RequirementContainer>
  );
}
