import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./common/Footer/Footer";
import NavBar from "./common/NavBar";
import ScrollTopButton from "./common/ScrollTopButton";
import {
  Contact,
  Error404,
  Home,
  Maintenance,
  Requirement,
  Resume,
} from "./pages";

// userid 1761832990658005 fb

function AppRouter() {
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await fetch("/api/maintenance")
        .then((res) => res.json())
        .then((result) => {
          const temp = result;
          temp.maintenance = result?.maintenance === "1";
          setConfig(temp);
          setLoading(false);
        });
    })();
  }, []);

  return (
    <BrowserRouter>
      <div style={{ height: "100%", position: "relative" }}>
        {!config?.maintenance && <NavBar />}
        <main>
          <Routes>
            {config?.maintenance ? (
              <Route path="*" element={<Maintenance />} />
            ) : (
              <Fragment>
                {!loading && (
                  <Fragment>
                    <Route path="/" element={<Home />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="post-resume" element={<Resume />} />
                    <Route
                      path="hospital-requirement"
                      element={<Requirement />}
                    />
                    <Route path="404" element={<Error404 />} />
                    <Route path="*" element={<Error404 />} />
                  </Fragment>
                )}
              </Fragment>
            )}
          </Routes>
        </main>
        <ScrollTopButton />
        {!config?.maintenance && <Footer />}
      </div>
    </BrowserRouter>
  );
}

export default AppRouter;
