import styled from "styled-components";

export const FooterContainer = styled.footer`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 10%;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.bgdefault};
  letter-spacing: 0.025em;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;

  ${({ theme }) => theme.media.largeMobileUp} {
    flex-direction: row;
  }

  p {
    font-weight: 500;
    letter-spacing: 0.05em;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .Heading {
    margin-top: 15px;
  }

  h6 {
    margin-bottom: 10px;
  }
`;

export const LogoContainer = styled.div`
  height: 60px;
`;

export const CopyrightContainer = styled.div`
  margin-top: 10%;
  border-top: 1px solid ${({ theme }) => theme.colors.bgdefault};
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({ theme }) => theme.media.largeMobileUp} {
    justify-content: center;
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  height: min-content;
  width: min-content;
  margin: 0;
  padding: 6px;
  margin-right: 10px;

  border-radius: 1000px;
  background-color: ${({ theme }) => theme.colors.bghover};
  color: ${({ theme }) => theme.colors.bgdefault};

  &:hover {
    background-color: ${({ theme }) => theme.colors.textsecondary};
    color: ${({ theme }) => theme.colors.textdefault};
  }

  transition: all 0.3s ease-in-out;
`;

export const Tab = styled.div`
  margin-top: 15px;
  cursor: pointer;
  .Icon {
    margin-right: 10px;
  }
  .Heading {
    margin: 0;
  }

  &:hover {
    opacity: 0.8;
    text-decoration: underline;
  }
`;
