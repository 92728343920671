import styled from "styled-components";

export const ContactContainer = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  padding-top: 100px;
  padding-inline: 10%;
  padding-bottom: 50px;
  letter-spacing: 0.025em;

  ${({ theme }) => theme.media.tabletLandscapeUp} {
    padding-inline: 50px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 10%;
  margin-top: 40px;

  .Heading {
    margin-bottom: 15px;
  }

  .companyDescription {
    font-weight: 600;
  }

  .contactDescription {
    display: flex;
    flex-wrap: wrap;
  }

  .contactInfo {
    margin-top: 25px;
  }

  ${({ theme }) => theme.media.tabletLandscapeUp} {
    margin-top: unset;
  }
`;

export const HyperLink = styled.a`
  text-decoration: none;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    margin-bottom: 15px;
  }

  .note {
    color: ${({ theme }) => theme.colors.warn700};
  }
`;

export const InputContainer = styled.div`
  margin-top: 20px;
`;

export const SubmitBtnContainer = styled.div`
  margin-top: 20px;
  max-width: 100px;
`;

export const SocialContainer = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  height: min-content;
  width: min-content;
  margin: 0;
  padding: 6px;
  margin-right: 10px;

  border-radius: 1000px;

  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.light};

  &:hover {
    transform: scale(1.2);
    background-color: ${({ theme }) => theme.colors.textsecondary};
  }

  transition: all 0.3s ease-in-out;
`;
