import React from "react";
import { Heading, Icon, Image } from "darshanyeah-react-ui";
import {
  CopyrightContainer,
  FooterContainer,
  IconButton,
  LogoContainer,
  Section,
  SectionContainer,
  SocialContainer,
  Tab,
} from "./Footer.css";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  const activeTab = (path) => {
    if (window.location.pathname === path) return true;
    return false;
  };

  const handleSocialClick = (e) => {
    switch (e) {
      case "wp":
        window.open("https://wa.me/919898138301");
        break;

      case "ig":
        window.open("https://instagram.com/placedoctor");
        break;

      case "fb":
        window.open("https://facebook.com/hpdrplacement");
        break;

      default:
        break;
    }
  };

  return (
    <FooterContainer>
      <SectionContainer>
        <Section>
          <LogoContainer>
            <Image url="/logo192.png" height="inherit" />
          </LogoContainer>
          <Heading variant="p">
            We provide only the highest level of services in every step of the
            way.
          </Heading>
          <SocialContainer>
            <IconButton
              aria-label="Whatsapp-Button"
              onClick={() => handleSocialClick("wp")}
            >
              <Icon iconName="bs/BsWhatsapp" size="20px" />
            </IconButton>
            <IconButton
              aria-label="Facebook-Button"
              onClick={() => handleSocialClick("fb")}
            >
              <Icon iconName="bs/BsFacebook" size="20px" />
            </IconButton>
            <IconButton
              aria-label="Instagram-Button"
              onClick={() => handleSocialClick("ig")}
            >
              <Icon iconName="bs/BsInstagram" size="20px" />
            </IconButton>
          </SocialContainer>
        </Section>
        <Section>
          <Heading variant="h6">Company</Heading>
          {!activeTab("/") && (
            <Tab
              aria-label="Navigates to Home Page"
              onClick={() => navigate("/")}
            >
              <Heading variant="p">
                <Icon iconName="fa/FaHome" size="20px" />
                Home
              </Heading>
            </Tab>
          )}
          {!activeTab("/contact") && (
            <Tab
              aria-label="Navigates to Contact Page"
              onClick={() => navigate("/contact")}
            >
              <Heading variant="p">
                <Icon iconName="fa/FaRegHandshake" size="20px" />
                Contact
              </Heading>
            </Tab>
          )}
          {!activeTab("/post-resume") && (
            <Tab
              aria-label="Navigates to Resume Page"
              onClick={() => navigate("/post-resume")}
            >
              <Heading variant="p">
                <Icon iconName="fa/FaRegAddressCard" size="20px" />
                Apply Job
              </Heading>
            </Tab>
          )}
          {!activeTab("/hospital-requirement") && (
            <Tab
              aria-label="Navigates to Hospital Requirement Page"
              onClick={() => navigate("/hospital-requirement")}
            >
              <Heading variant="p">
                <Icon iconName="ci/CiHospital1" size="20px" />
                Apply Requirement
              </Heading>
            </Tab>
          )}
        </Section>
        <Section>
          <Heading variant="h6">Business Hours</Heading>
          <Heading variant="p">Monday - Friday : 9am to 10pm</Heading>
          <Heading variant="p">Saturday : 9am to 5pm</Heading>
          <Heading variant="p">Sunday : Day Off (Holiday)</Heading>
        </Section>
      </SectionContainer>
      <CopyrightContainer>
        <Heading variant="p">
          2019 &copy; All Rights Reserved | HP Dr Placement
        </Heading>
      </CopyrightContainer>
    </FooterContainer>
  );
}
