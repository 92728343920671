import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import AppRouter from "./AppRouter";
import Theme, { GlobalStyles } from "./utils/Theme";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Fragment>
      <ThemeProvider theme={Theme}>
        <ToastContainer limit={4} autoClose={6000} />
        <GlobalStyles />
        <AppRouter />
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
