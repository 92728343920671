import React from "react";
import c from "classnames";
import {
  CheckBoxContainer,
  CheckBoxLabel,
  StyledCheckBox,
} from "./CheckBox.css";
import { any, bool, func, string } from "prop-types";

/**
 *
 * @param {*} {`alt: string`,`checked: bool`,`className: string`,`disabled: bool`,`id: string`,`label: any`,`name: string`,`onChange: func`,`reverse: bool`}
 * @returns
 */

export function CheckBox({
  alt,
  checked,
  className,
  disabled,
  id,
  label,
  name,
  onChange,
  reverse,
}) {
  return (
    <CheckBoxContainer reverse={reverse}>
      <StyledCheckBox
        type="checkbox"
        alt={alt}
        checked={checked}
        className={c("CheckBox", className)}
        id={id}
        name={name}
        disabled={disabled}
        onChange={onChange}
        reverse={reverse}
        label={label}
      />
      {label && (
        <CheckBoxLabel className="CheckBox-label">{label}</CheckBoxLabel>
      )}
    </CheckBoxContainer>
  );
}

CheckBox.propsTypes = {
  alt: string,
  checked: bool,
  className: string,
  disabled: bool,
  id: string,
  label: any,
  name: string,
  onChange: func,
  reverse: bool,
};
