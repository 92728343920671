import styled from "styled-components";

export const RequirementContainer = styled.div`
  display: flex;
  padding-top: 100px;
  padding-inline: 10%;
  padding-bottom: 50px;
  letter-spacing: 0.025em;

  ${({ theme }) => theme.media.tabletLandscapeUp} {
    padding-inline: 50px;
  }

  .note {
    color: ${({ theme }) => theme.colors.warn700};
    margin-top: 15px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const InputContainer = styled.div`
  margin-top: 20px;

  .label {
    font-size: 0.9rem;
    font-weight: 600;
  }
`;
