import { Button, Heading, Icon, Input, TextArea } from "darshanyeah-react-ui";
import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  CONTACT_PAGE_ERROR_1,
  CONTACT_PAGE_ERROR_2,
  CONTACT_PAGE_ERROR_3,
  CONTACT_PAGE_SUCCESS_1,
} from "../../utils/reactToastifyToastIds";
import { emailPattern, mobilePattern } from "../../utils/regex";
import useObjectHasKey from "../../utils/useObjectHasKey";
import {
  ContactContainer,
  FormContainer,
  HyperLink,
  IconButton,
  InfoContainer,
  InputContainer,
  SocialContainer,
  SubmitBtnContainer,
} from "./Contact.css";

export default function Contact() {
  const [record, setRecord] = useState({});
  const [errors, setErrors] = useState({});

  const [showMobileHint, setShowMobileHint] = useState(false);

  const handleChange = (key, value) => {
    setRecord((prev) => ({ ...prev, [key]: value }));
  };

  const handleSocialClick = (e) => {
    switch (e) {
      case "wp":
        window.open("https://wa.me/919898138301");
        break;

      case "ig":
        window.open("https://instagram.com/placedoctor");
        break;

      case "fb":
        window.open("https://facebook.com/hpdrplacement");
        break;

      default:
        break;
    }
  };

  const namekey = useObjectHasKey(record, "name");
  const emailkey = useObjectHasKey(record, "email");
  const mobilekey = useObjectHasKey(record, "mobile");

  useMemo(() => {
    // name validation
    if (namekey) {
      if (record?.name?.length >= 5)
        setErrors((prev) => ({ ...prev, name: false }));
      else setErrors((prev) => ({ ...prev, name: true }));
    }

    // email validation
    if (emailkey) {
      if (emailPattern.test(record?.email))
        setErrors((prev) => ({ ...prev, email: false }));
      else setErrors((prev) => ({ ...prev, email: true }));
    }

    // mobile validation
    if (mobilekey) {
      if (mobilePattern.test(record?.mobile))
        setErrors((prev) => ({ ...prev, mobile: false }));
      else setErrors((prev) => ({ ...prev, mobile: true }));
    }
  }, [emailkey, mobilekey, namekey, record]);

  const validate = () => {
    let valid = true;

    if (record?.name?.length <= 5) {
      toast.error("Please fill mandatory fields !!", {
        toastId: CONTACT_PAGE_ERROR_1,
      });
      setErrors((prev) => ({ ...prev, name: true }));
      valid = false;
    } else if (!emailPattern.test(record?.email)) {
      toast.error("Please fill mandatory fields !!", {
        toastId: CONTACT_PAGE_ERROR_1,
      });
      setErrors((prev) => ({ ...prev, email: true }));
      valid = false;
    } else if (!mobilePattern.test(record?.mobile)) {
      toast.error("Please fill mandatory fields !!", {
        toastId: CONTACT_PAGE_ERROR_1,
      });
      setShowMobileHint(true);
      setErrors((prev) => ({ ...prev, mobile: true }));
      valid = false;
    } else {
      setShowMobileHint(false);
      valid = true;
    }

    return valid;
  };

  const handleSubmit = () => {
    if (validate()) {
      fetch("/api/contact/", {
        method: "POST",
        body: JSON.stringify({
          name: record?.name,
          email: record?.email,
          mobile: record?.mobile,
          message: record?.message || "",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === 1) {
            toast.success("Form Submitted Successfully", {
              toastId: CONTACT_PAGE_SUCCESS_1,
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            toast.error("Opps, something has gone wrong", {
              toastId: CONTACT_PAGE_ERROR_2,
            });
            toast.error("Form submission failed !!!", {
              toastId: CONTACT_PAGE_ERROR_3,
            });
          }
        })
        .catch((e) => {
          toast.error("Opps, something has gone wrong", {
            toastId: CONTACT_PAGE_ERROR_2,
          });
          toast.error("Form submission failed !!!", {
            toastId: CONTACT_PAGE_ERROR_3,
          });
        });
    }
  };

  return (
    <ContactContainer>
      <InfoContainer>
        <Heading variant="h3">About Company</Heading>
        <Heading className="companyDescription" variant="h6">
          Hp Doctor Placement is providing{" "}
          <b>Doctor Recruitment and Complete Placement Services</b> acrross
          Gujarat-India (private hospitals). We will be glad & privileged to be
          at your services, with an assurance of our personal attention, care &
          best services at all times.
        </Heading>

        <Heading className="contactInfo">Contact Info</Heading>
        <Heading className="contactDescription" variant="h6">
          Email:&nbsp;&nbsp;
          <Heading className="companyDescription" variant="h6">
            <HyperLink href="mailto:contact@hpdrplacement.com">
              contact@hpdrplacement.com
            </HyperLink>
          </Heading>
        </Heading>
        <Heading className="contactDescription" variant="h6">
          Mobile:&nbsp;&nbsp;
          <Heading className="companyDescription" variant="h6">
            <HyperLink href="tel:+919898138301">+91-98-98-138-301</HyperLink>
          </Heading>
        </Heading>
        <Heading variant="h6">
          Socials:&nbsp;&nbsp;
          <SocialContainer>
            <IconButton
              aria-label="Whatsapp-Button"
              onClick={() => handleSocialClick("wp")}
            >
              <Icon iconName="bs/BsWhatsapp" size="20px" />
            </IconButton>
            <IconButton
              aria-label="Facebook-Button"
              onClick={() => handleSocialClick("fb")}
            >
              <Icon iconName="bs/BsFacebook" size="20px" />
            </IconButton>
            <IconButton
              aria-label="Instagram-Button"
              onClick={() => handleSocialClick("ig")}
            >
              <Icon iconName="bs/BsInstagram" size="20px" />
            </IconButton>
          </SocialContainer>
        </Heading>
      </InfoContainer>

      <FormContainer>
        <Heading className="title" variant="h5">
          Connect Now
        </Heading>
        <Heading className="note" variant="p">
          Fields marked with * are mandatory
        </Heading>
        <InputContainer>
          <Input
            labelText="Full Name"
            inputType="text"
            errorText="Please introduce a valid name !"
            onChange={(event) => handleChange("name", event.target.value)}
            error={errors?.name}
            required
          />
        </InputContainer>
        <InputContainer>
          <Input
            labelText="Email"
            inputType="email"
            errorText="The entered E-MAIL is invalid !"
            onChange={(event) => handleChange("email", event.target.value)}
            error={errors?.email}
            required
          />
        </InputContainer>
        <InputContainer>
          <Input
            labelText="Mobile Number"
            inputType="number"
            hint={showMobileHint ? "Please add the country code" : ""}
            errorText="Please enter valid Mobile Number !"
            onChange={(event) => handleChange("mobile", event.target.value)}
            error={errors?.mobile}
            required
          />
        </InputContainer>
        <InputContainer>
          <TextArea
            labelText="Message"
            onChange={(event) => handleChange("message", event.target.value)}
            width="auto"
          />
        </InputContainer>

        <SubmitBtnContainer>
          <Button
            label="Submit"
            variant="primary-bordered"
            onClick={handleSubmit}
          />
        </SubmitBtnContainer>
      </FormContainer>
    </ContactContainer>
  );
}
