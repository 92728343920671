export const CONTACT_PAGE_ERROR_1 = "contact-page-error-1"; // Please fill mandatory fields !!
export const CONTACT_PAGE_ERROR_2 = "contact-page-error-2"; // Opps, something has gone wrong
export const CONTACT_PAGE_ERROR_3 = "contact-page-error-3"; // Form submission failed !!!

export const CONTACT_PAGE_SUCCESS_1 = "contact-page-success-1"; // Form Submitted Successfully

export const RESUME_PAGE_ERROR_1 = "resume-page-error-1"; // Please fill mandatory fields !!
export const RESUME_PAGE_ERROR_2 = "resume-page-error-2"; // Opps, something has gone wrong
export const RESUME_PAGE_ERROR_3 = "resume-page-error-3"; // Form submission failed !!!

export const RESUME_PAGE_SUCCESS_1 = "resume-page-success-1"; // Form Submitted Successfully

export const REQUIREMENT_PAGE_ERROR_1 = "requirement-page-error-1"; // Please fill mandatory fields !!
export const REQUIREMENT_PAGE_ERROR_2 = "requirement-page-error-2"; // Opps, something has gone wrong
export const REQUIREMENT_PAGE_ERROR_3 = "requirement-page-error-3"; // Form submission failed !!!

export const REQUIREMENT_PAGE_SUCCESS_1 = "requirement-page-success-1"; // Form Submitted Successfully
