import { Heading, Image } from "darshanyeah-react-ui";
import React from "react";
import { LogoContainer } from "../../common/Footer/Footer.css";
import { HyperLink } from "../Contact/Contact.css";
import { ContactContainer, Container } from "./Maintenance.css";

export default function Maintenance() {
  return (
    <Container>
      <Heading>Site is under Maintenance</Heading>

      <Heading variant="h3">Please Contact site owner</Heading>

      <ContactContainer>
        <LogoContainer>
          <Image url="/logo192.png" height="inherit" />
        </LogoContainer>

        <div>
          <Heading variant="h6">Hitendra Parmar</Heading>

          <Heading variant="h6">
            <HyperLink href="tel:+919898138301">+91-98-98-138-301</HyperLink>
          </Heading>
        </div>
      </ContactContainer>
    </Container>
  );
}
