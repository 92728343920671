import styled from "styled-components";
import heroBanner from "../../assets/images/hero.png";
import heroBannerMob from "../../assets/images/announce_op30.png";
import doctor from "../../assets/images/doctor.png";
import recruiter from "../../assets/images/recruiter.png";

// Hero section
export const HeroContainer = styled.div`
  // background-image: url(${heroBanner});
  background-repeat: no-repeat;
  background-position: 50% 85%;
  background-size: 100%;
  max-width: 100vw;
  background-color: aliceblue;
  display: flex;
  padding-inline: 10%;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 50px;
  background-image: url(${heroBannerMob});
  ${({ theme }) => theme.media.largeMobileUp} {
    align-items: center;
    background-size: 90%;
    min-height: 100vh;
  }
  ${({ theme }) => theme.media.tabletLandscapeUp} {
    background-image: unset;
    margin-top: 30px;
    padding-inline: 50px;
  }
  ${({ theme }) => theme.media.desktopUp} {
    margin-top: 0;
  }
`;

export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  .needs {
    margin: 30px 0;
    font-size: 56px;
    line-height: 60px;
    word-wrap: break-word;
    text-shadow: 3px 3px 2px #fa923f;
    ${({ theme }) => theme.media.largeMobileUp} {
      font-size: 80px;
      line-height: 80px;
    }
  }

  .tag-line {
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    margin-bottom: 30px;
    text-transform: capitalize;
    > mark {
      background-color: #fa923f60;
    }
  }

  .Button {
    max-width: fit-content;
    background-color: transparent;
  }
`;

export const ImageContainer = styled.div`
  display: none;
  ${({ theme }) => theme.media.tabletLandscapeUp} {
    display: unset;
  }
`;
// Hero section

// Candidate section
export const CandidateContainer = styled.div`
  background-image: url(${doctor});
  background-repeat: no-repeat;
  background-position: 100% 95%;
  background-size: 60%;

  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.bgdefault} !important;

  display: flex;
  flex-direction: column;

  height: 600px;
  padding-inline: 10%;
  padding-top: 50px;

  .candidate-subtitle,
  .candidate-description {
    margin-top: 20px;
  }

  .candidate-subtitle {
    border-left: 5px solid ${({ theme }) => theme.colors.bgdefault};
    padding-left: 8px;
  }

  .candidate-description {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.0025em;
    line-height: 30px;
    text-transform: capitalize;
    > mark {
      background-color: #fa923f60;
      color: ${({ theme }) => theme.colors.bgdefault} !important;
    }
  }

  .submit-btn {
    margin-top: 30px;
    width: fit-content;
  }
  ${({ theme }) => theme.media.largeMobileUp} {
    background-position: 100% 95%;
    background-size: 40%;
    padding-inline: 50px;
  }
  ${({ theme }) => theme.media.tabletLandscapeUp} {
    background-size: 25%;

    .candidate-title,
    .candidate-subtitle,
    .candidate-description {
      letter-spacing: 0.025em;
    }
  }
`;

// Recruiter section
export const RecruiterContainer = styled.div`
  background-image: url(${recruiter});
  background-repeat: no-repeat;
  background-position: 85% 95%;
  background-size: 45%;

  color: ${({ theme }) => theme.colors.textdefault};

  align-items: flex-start;
  display: flex;
  flex-direction: column;

  height: 600px;
  padding-inline: 10%;
  padding-top: 50px;
  text-transform: capitalize;

  .recruiter-subtitle,
  .recruiter-description {
    margin-top: 20px;
  }

  .recruiter-subtitle {
    font-size: 28px;
    line-height: 30px;

    ${({ theme }) => theme.media.tabletPortraitUp} {
      font-size: 32px;
      line-height: 40px;
    }

    ${({ theme }) => theme.media.tabletLandscapeUp} {
      font-size: 40px;
      line-height: 51px;
    }
  }

  .recruiter-subtitle {
    border-left: 5px solid ${({ theme }) => theme.colors.textdefault};
    padding-left: 8px;
  }

  .recruiter-description {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.0025em;
    line-height: 32px;
    margin-bottom: 30px;
    text-transform: capitalize;
    > mark {
      background-color: #fa923f60;
      color: inherit;
    }
  }

  ${({ theme }) => theme.media.largeMobileUp} {
    background-position: 85% 95%;
    background-size: 40%;
    padding-inline: 50px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp} {
    background-position: 85% 85%;
  }

  ${({ theme }) => theme.media.tabletLandscapeUp} {
    background-position: 90% 85%;
    background-size: 25%;
  }
`;
// Recruiter section

// Testimonial section
export const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary};
  padding-top: 50px;
  padding-bottom: 50px;
  padding-inline: 10%;
  min-height: 400px;

  .title {
    text-align: center;
  }

  .Heading {
    color: ${({ theme }) => theme.colors.bgdefault};
    margin-bottom: 40px;
  }

  ${({ theme }) => theme.media.largeMobileUp} {
    padding-inline: 50px;
  }
`;

export const CarouselContainer = styled.div`
  width: 100%;

  .Carousel {
    width: 100% !important;
  }

  .Slider {
    width: 100%;
  }

  ${({ theme }) => theme.media.tabletLandscapeUp} {
    width: 80%;
  }

  ${({ theme }) => theme.media.desktopUp} {
    width: 60%;
  }
`;
// Testimonial section
