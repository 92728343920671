import { Heading } from "darshanyeah-react-ui";
import styled, { css, keyframes } from "styled-components";

export const ResumeContainer = styled.div`
  display: flex;
  padding-top: 100px;
  padding-inline: 10%;
  padding-bottom: 50px;
  letter-spacing: 0.025em;

  ${({ theme }) => theme.media.tabletLandscapeUp} {
    padding-inline: 50px;
  }

  .note {
    color: ${({ theme }) => theme.colors.warn700};
    margin-top: 15px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const InputContainer = styled.div`
  margin-top: 20px;

  & + .higherEducationContainer {
    display: flex;
  }

  .date-picker {
    min-height: 48px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px !important;
    padding: 0 16px !important;
    border: 1px solid #cbd5e1 !important;
    background-color: #ffffff !important;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
    color: currentColor !important;
    transition: all 0.3s;
  }

  .DropDown > div {
    background-color: #ffffff;
    color: #6c757d;
  }

  ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: ${zIndex};
    `}
`;

export const Label = styled.label`
  display: flex;
  color: ${({ theme }) => theme.colors.textdefault};
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: capitalize;
  ${({ hasHint }) =>
    !hasHint &&
    css`
      padding-bottom: 10px;
    `}
`;

const show = keyframes`
0% {
  transform: translateY(-100%);
  opacity: 0;
}
100% {
  transform: translateY(0%);
  opacity: 1;
}`;

export const HintText = styled.div`
  color: ${({ theme }) => theme.colors.texthint};
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 0.025rem;
  margin-top: 0.15rem;
  animation: ${show} 0.3s ease-in-out;
  ${({ hasLabel }) =>
    hasLabel
      ? css`
          padding-bottom: 10px;
        `
      : css`
          padding-left: 20px;
        `}
`;

export const AddMoreButton = styled.div`
  margin-top: 20px;
  color: #94a3b8;
  button {
    border: 1px solid #94a3b8;
  }
`;

export const RemoveButton = styled.div`
  margin-top: 20px;
  color: #94a3b8;
  button {
    border: 1px solid red;
    color: red;
  }
`;

export const SubTitle = styled(Heading)`
  margin-top: 20px;
`;

export const SubDetailsContainer = styled.div`
  border: 2px solid #94a3b8;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 1px 5px 0px #cbd5e1;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: auto;
  column-gap: 30px;

  padding-bottom: 20px;
  border-bottom: 2px solid #94a3b8;

  &:nth-last-child(2) {
    padding-bottom: unset;
    border-bottom: none;
  }

  ${({ theme }) => theme.media.largeMobileUp} {
    grid-template-columns: auto auto;
  }
`;

export const ErrorText = styled.span`
  color: ${({ theme }) => theme.colors.warn500};
  font-size: 0.7rem;
  font-weight: 700;
  letter-spacing: 0.025rem;
  margin-top: 0.15rem;
  animation: ${show} 0.3s ease-in-out;
  padding-left: 20px;

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0 !important;
    `}
`;

export const Radio = styled.input`
  height: fit-content;
`;
