import {
  Button,
  Carousel,
  Heading,
  Image,
  Testimonial,
} from "darshanyeah-react-ui";
import React, { useEffect, useState } from "react";
import {
  CandidateContainer,
  CarouselContainer,
  HeroContainer,
  ImageContainer,
  RecruiterContainer,
  RightSection,
  TestimonialContainer,
} from "./Home.css";
import heroBanner from "../../assets/images/hero.png";
import docMalePng from "../../assets/images/doc-male.png";
import docFeMalePng from "../../assets/images/doc-female.png";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();

  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    (async () => {
      await fetch("/api/testimonial/")
        .then((res) => res.json())
        .then((res) => {
          const arr = [];
          res?.map((e) => {
            if (e?.image === "") {
              if (e?.gender === "female") {
                return arr.push({ ...e, image: docFeMalePng });
              } else if (e?.gender === "male") {
                return arr.push({ ...e, image: docMalePng });
              } else return arr.push({ ...e, image: docMalePng });
            } else return arr.push(e);
          });
          setTestimonials(arr);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Landing = () => (
    <HeroContainer>
      <RightSection>
        <Heading variant="h5">Welcome To</Heading>
        <Heading variant="h1">HP Doctor Placement</Heading>
        <Heading className="needs">We Fulfil Your Hospital Needs</Heading>
        <Heading className="tag-line" variant="p">
          The agency who is <mark>providing doctor jobs</mark> services in
          private hospitals
        </Heading>
        <Button
          label="Contact Now"
          variant="primary-bordered"
          icon="cg/CgUserList"
          onClick={() => navigate("/contact")}
        />
      </RightSection>
      <ImageContainer>
        <Image url={heroBanner} />
      </ImageContainer>
    </HeroContainer>
  );

  const Candidate = () => (
    <CandidateContainer>
      <Heading className="candidate-title" variant="h6">
        For Doctors
      </Heading>
      <Heading className="candidate-subtitle" variant="h2">
        Are you Searching for Job?
      </Heading>
      <Heading className="candidate-description" variant="p">
        <b>HP Dr Placement</b> is continuously looking for the{" "}
        <mark>best candidates like you</mark> to hire for our openings.
      </Heading>
      <Button
        className="submit-btn"
        label="Submit Resume"
        variant="primary-bordered"
        icon="im/ImProfile"
        onClick={() => navigate("/post-resume")}
      />
    </CandidateContainer>
  );

  const Recruiter = () => (
    <RecruiterContainer>
      <Heading className="recruiter-title" variant="h6">
        For Hospitals
      </Heading>
      <Heading className="recruiter-subtitle" variant="h3">
        Looking to hire doctor for you?
      </Heading>
      <Heading className="recruiter-description" variant="p">
        <b>HP Dr Placement</b> provides you the awesome service for{" "}
        <mark>hiring the best talent</mark> within your expected duration.
      </Heading>
      <Button
        className="submit-btn"
        label="Share Requirement"
        variant="primary-bordered"
        icon="hi/HiOutlineUserAdd"
        onClick={() => navigate("/hospital-requirement")}
      />
    </RecruiterContainer>
  );

  const Testimonials = () => {
    return (
      <React.Fragment>
        <TestimonialContainer>
          <Heading className="title" variant="h1">
            What our client says
          </Heading>
          <CarouselContainer>
            {testimonials && (
              <Carousel
                height="auto"
                width="min-content"
                slideArray={testimonials.map((e, i) => (
                  <Testimonial
                    key={i}
                    image={e.image}
                    degree={e.degree}
                    description={e.description}
                    name={e.name}
                  />
                ))}
                autoPlay
              />
            )}
          </CarouselContainer>
        </TestimonialContainer>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Landing />
      <Candidate />
      <Recruiter />
      <Testimonials />
    </React.Fragment>
  );
}
