import React, { useState } from "react";
import Toolbar from "./ToolBar/Toolbar";
import { PlacementSidePanel } from "darshanyeah-react-ui";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function NavBar() {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen((state) => !state);
  };

  const navigate = useNavigate();

  const checkCurrentLocation = (path) => {
    if (window.location.pathname === path) return true;
    return false;
  };

  const changeLocation = (path) => {
    navigate(path);
    setSideDrawerOpen(false);
  };

  const tabs = [
    {
      title: "Home",
      active: checkCurrentLocation("/"),
      order: 0,
      hoverIconEnable: true,
      rightIcon: {
        name: "fa/FaHome",
        size: "1.3rem",
      },
      onClick: () => changeLocation("/"),
    },
    {
      title: "Contact",
      active: checkCurrentLocation("/contact"),
      order: 1,
      hoverIconEnable: true,
      rightIcon: {
        name: "fa/FaRegHandshake",
        size: "1.3rem",
      },
      onClick: () => changeLocation("/contact"),
    },
    {
      title: "Submit Resume",
      active: checkCurrentLocation("/post-resume"),
      order: 2,
      hoverIconEnable: true,
      rightIcon: {
        name: "fa/FaRegAddressCard",
        size: "1.3rem",
      },
      onClick: () => changeLocation("/post-resume"),
    },
    {
      title: "Hospital Requirement",
      active: checkCurrentLocation("/hospital-requirement"),
      order: 3,
      hoverIconEnable: true,
      rightIcon: {
        name: "ci/CiHospital1",
        size: "1.5rem",
      },
      onClick: () => changeLocation("/hospital-requirement"),
    },
  ];

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <div>
      <Toolbar drawerClickHandler={drawerToggleClickHandler} />
      <PlacementSidePanel
        isVisible={sideDrawerOpen}
        onClose={() => setSideDrawerOpen(false)}
        title="Hp Dr Placement"
        tabs={tabs}
      />
    </div>
  );
}
