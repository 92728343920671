import { Icon } from "darshanyeah-react-ui";
import React, { useState } from "react";
import styled from "styled-components";

const Button = styled.button`
  height: 35px;
  width: 35px;

  display: ${({ show }) => (show ? "flex" : "none")};
  align-items: center;
  justify-content: center;

  right: 5%;
  bottom: 5%;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.maticon};
  border: 0;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 4px ${({ theme }) => theme.colors.bghover};
  color: ${({ theme }) => theme.colors.bgdefault};
  z-index: 999999999999999;
`;

export default function ScrollTopButton() {
  const [show, setShow] = useState(false);

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <Button show={show} onClick={topFunction}>
      <Icon iconName="hi2/HiArrowUpOnSquare" size="1.3rem" />
    </Button>
  );
}
